
  import Vue from 'vue';

  export default Vue.extend({
    created() {
      this.$store.commit('breadcrumbList', [
        {
          name: '等级权限',
          path: 'level',
        },
      ]);
    },
  });

